@import "reset";

@import "font";


body{
	background: #fff;
	font-size: 15px;
	font-family: Georgia, sans-serif, Verdana, Arial, Georgia;
}



@mixin respond-to($media) {
  @if $media == handhelds {
    @media only screen and (max-width: 479px) { @content; }
  }
  @else if $media == wide-handhelds {
    @media only screen and (min-width: 480px) and (max-width: 599px) { @content; }
  }
  @else if $media == small-tablets {
    @media only screen and (min-width: 600px) and (max-width: 767px) { @content; }
  }  
  @else if $media == tablets {
    @media only screen and (min-width: 768px) and (max-width: 979px) { @content; }
  }
  @else if $media == display {
    @media only screen and (min-width: 980px) and (max-width: 1199px) { @content; }
  }  
  @else if $media == display-big {
    @media only screen and (min-width: 1200px) { @content; }
  }
}


@mixin clearfix { &:after {  content: ".";  display: block;  clear: both;  visibility: hidden;  line-height: 0;  height: 0;} }
// @include clearfix;


@mixin width1000 { max-width: 1000px; margin: 0 auto; position: relative; box-sizing: border-box;}
// @include width1000;
@mixin width100pr { width: 100%; margin: 0 auto; position: relative; box-sizing: border-box;}
// @include width1000;





@mixin common-text-default{
	h1,h2,h3,h4{
		margin-bottom: 15px;
	}
	p{
		margin-bottom: 10px;
	}
	a{
		text-decoration: none;
		color: #000;
		margin-bottom:10px;
	}
	table{
		margin-bottom: 10px;
		tr{
			padding: 0;
			vertical-align: top;
		}
		td{
			padding: 10px 0;
			vertical-align: top;
		}
	}
	ol{
		padding: 0 30px;
		margin-bottom: 20px;
		line-height: 130%;
		list-style-type: disc;
		li{
			padding: 0;
			margin: 4px 0;
		}
	}
	ul{
		padding: 0 10px;
		margin-bottom: 20px;
		line-height: 1;
		li{
			padding: 0;
			margin: 4px 0;
		}
	}
	img{
		width: 100%;
	}
}
//text styles @include common-text-default;



div,ul,ol,li,input{
	box-sizing: border-box;
}



@import "header";

@import "top-menu";

@import "content";

@import "left-block";

@import "text-block";

@import "contact-block.scss";

@import "footer";