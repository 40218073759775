.adress{
	width: 55%;
	margin: 20px auto;
	@include clearfix;
	
	.adress-text{
		margin: 0;

		img{
			float: left;
			width: 50px;
			height: 50px;
			margin-right: 15px;
			
			@include respond-to(handhelds){
				width: 35px;
				height: 35px;
			}
		}
		
		h2{
			@include respond-to(handhelds){
				font-size: 20px;
			}
		}
		h3,p{
			margin-bottom: 5px;
		}
	}

	
	@include respond-to(display){
		width: 55%;
	}
	@include respond-to(tablets){
		width: 60%;
	}
	@include respond-to(small-tablets){
		width: 85%;
	}
	@include respond-to(wide-handhelds){
		width: 80%;
	}
	@include respond-to(handhelds){
		width: 100%;
	}
}

.list-contacts{
	width: 55%;
	margin: 40px auto;
	
	.adress-text{
		margin-bottom: 0;
		@include clearfix;
		
		img{
			float: left;
			width: 50px;
			height: 50px;
			
			@include respond-to(handhelds){
				width: 30px;
				height: 30px;
			}
		}
		
		p{
			float: left;
			margin-left: 15px;
			margin-top: 10px;
			font-size: 22px;
			font-weight: 400;
			
			@include respond-to(handhelds){
				margin-top: 0;
				font-size: 20px;
			}
		}
	}

	
	@include respond-to(display){
		width: 55%;
	}
	@include respond-to(tablets){
		width: 60%;
	}
	@include respond-to(small-tablets){
		width: 85%;
	}
	@include respond-to(wide-handhelds){
		width: 80%;
	}
	@include respond-to(handhelds){
		width: 100%;
	}
}

.konta{
	width: 70%;
	margin: 40px auto 0;
	
	h2,h3{
		margin-bottom: 0;
		font-size: 23px;
		font-weight: 400;
	}
	p{
		font-size: 18px;
		font-weight: 400;
		text-align: left;
		
		@include respond-to(handhelds){
			margin-top: 0;
			font-size: 14px;
		}
	}
	
	
	@include respond-to(display){
		width: 70%;
	}
	@include respond-to(tablets){
		width: 70%;
	}
	@include respond-to(small-tablets){
		width: 80%;
	}
	@include respond-to(wide-handhelds){
		width: 80%;
	}
	@include respond-to(handhelds){
		width: 100%;
	}
}

.map-block-big{
	width: 90%;
	margin: 20px auto;
	line-height: 0;
	
	img{
		width: 100%;
	}
}


.title-map{
	text-align: center;
}

.map-block-small{
	width: 40%;
	margin: 20px auto;
	padding: 6px;
	background-color:#fff;
	border: 1px solid #04A2A3;
	overflow: hidden;
	line-height: 0;
	
	iframe{
		width: 100%;
		height: 10%;
	}
	iframe:hover{
		transform: scale(1.01);
		transition-duration: 0.5s;
	}

	@include respond-to(wide-handhelds){
		width: 60%;
	}
	@include respond-to(handhelds){
		width: 80%;
	}
}
.map-block-small:hover{
	border: 1px solid #E46E9C;
}